import { Box, Button, Container, Paper, Typography } from "@material-ui/core";
import { Transform } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import ComplaintDetails from "../../../components/complaint-details/ComplaintDetails";
import Header from "../../../components/header/Header";
import { AppUtils } from "../../../utils/appUtils";
import { ROLE_USER, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AssignDepartment from "./helpers/AssignDepartment";
import styles from "./style";

export default function Complaint(props) {
  const classes = styles();
  const [complaintData, setComplaintData] = useState([]);
  const [showAssignDepartmentModal, setShowAssignDepartmentModal] = useState(false);
  let ticketId = AppUtils.getUrlParam("id");
  const role = AppUtils.getUserRole();

  useEffect(() => {
    ticketId &&
      getComplaintDetails();
  }, []);

  const getComplaintDetails = () => {
    JA.get(API_URL.complaintDetail + "/" + ticketId)
      .then(response => {
        setComplaintData(response.data);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  function submitAssignDepartment(data) {
    data.id = complaintData.id;
    JA.post(API_URL.complaintDetail + "/update-departments", data)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          setShowAssignDepartmentModal(false);
          getComplaintDetails();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <Container maxWidth="xl" className={classes.root} disableGutters>
      <Header />
      <Box className={classes.complaintDetailsHeading}>
        <Typography variant="h6">गुनासो/उजुरी विवरण</Typography>
        <Box display="flex" alignItems="center">
          {role !== ROLE_USER &&
            <Button variant="contained" color="primary" onClick={() => { setShowAssignDepartmentModal(true) }} startIcon={<Transform />}>उपर्युक्त शाखा／कार्यालयमा पठाउनुहोस्</Button>
          }
        </Box>
      </Box>
      <Paper className={classes.complaintDetailsContainer}>
        <ComplaintDetails data={complaintData} getComplaintDetails={getComplaintDetails} />
      </Paper>
      <AssignDepartment showModal={showAssignDepartmentModal} handleModalClose={() => setShowAssignDepartmentModal(false)} handleModalSubmit={(data) => submitAssignDepartment(data)} />
    </Container>
  );
}
