import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  signUpBtnContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },
  heading: {
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid " + theme.palette.divider,
  }
}));
export default styles;
