import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/modal/CustomModal";
import DepartmentSelect from "../../components/complaints-table/DepartmentSelect/DepartmentSelect";

export default function AssignDepartment({ showModal, handleModalClose, handleModalSubmit, ...props }) {
  const { handleSubmit, reset, setValue, register } = useForm();

  useEffect(() => {
    !showModal && reset({});
  }, [showModal, reset]);

  useEffect(() => {
    register({ name: "departments" });
  }, [register]);

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  const handleDepartmentChange = (name, value) => {
    setValue(name, value);
  }

  return (
    <CustomModal
      title="चयनित गुनासो उपर्युक्त शाखा／कार्यालयमा पठाउनुहोस्।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showModal}
      onModalClose={handleModalClose}
      fullScreen
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <DepartmentSelect
            placeholder="गुनासो दर्ता गर्नुपर्ने शाखा／कार्यालय"
            onChange={handleDepartmentChange}
            isMulti
            creatable
          />
        </Grid>
      </Grid>
    </CustomModal>
  );

}