import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import AddMunicipalityModal from "../components/add-municipality/AddMunicipality";
import MunicipalitiesList from "./helpers/MunicipalityList";
import styles from "./style";

export default function AdminDashboard() {
  const classes = styles();
  const [openAddMunicipalityModal, setOpenAddMunicipalityModal] = useState(false);
  const [municipalityTableData, setMunicipalityTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});

  useEffect(() => {
    getMunicipalitiesData();
  }, []);

  const handleAddMunicipalityModalClose = () => {
    setModalDefaultValues({});
    setOpenAddMunicipalityModal(false);
  }

  const getMunicipalitiesData = () => {
    JA.get(API_URL.municipality)
      .then(response => {
        setMunicipalityTableData(response.data);
        setOpenAddMunicipalityModal(false);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const handleRowEdit = id => {
    JA.get(API_URL.municipality + "/" + id).then(response => {
      setModalDefaultValues(response.data);
      setOpenAddMunicipalityModal(true);
    }).catch(error => {
      AddAlertMessage({ type: "error", SOMETHING_WENT_WRONG });
    });
  }

  const handleAddMunicipalityModalSubmit = data => {
    data.id = modalDefaultValues.id;
    JA.post(API_URL.municipality, data)
      .then(response => {
        if (response.data.type === "success") {
          setMunicipalityTableData(response.data.objectList);
          handleAddMunicipalityModalClose();
        }
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
      })
      .catch(error => {
        AddAlertMessage({
          type: "error",
          message: SOMETHING_WENT_WRONG
        });
      });
  }

  return (
    <>
      <Box className={classes.dashboardContainer} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h5">
          जन-आवाज प्रणाली - सबै नगरपालिकाहरु
        </Typography>
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddMunicipalityModal(true) }}>नयाँ पालिका थप्नुहोस्</Button>
        </Box>
      </Box>
      <Box>
        <MunicipalitiesList tableData={municipalityTableData} editRow={handleRowEdit} />
      </Box>
      <AddMunicipalityModal handleModalSubmit={handleAddMunicipalityModalSubmit.bind(this)} showAddMunicipalityModal={openAddMunicipalityModal} handleAddMunicipalityModalClose={handleAddMunicipalityModalClose} defaultValues={modalDefaultValues} />
    </>
  );
}
