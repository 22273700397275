import { USER_ROLE } from "../utils/constants/index";
import { Cookies } from "../utils/storage/cookies";
import { LocalStorage } from "./storage/localStorage";
import { SessionStorage } from "./storage/sessionStorage";

export const AppUtils = {
  getUserRole() {
    return Cookies.readCookie(USER_ROLE);
  },

  removeUserRef() {
    Cookies.deleteCookie(USER_ROLE);
    SessionStorage.clear();
    LocalStorage.clear();
  },

  getUrlParam(key) {
    let pageUrl = window.location.search.substring(1);
    let urlParams = pageUrl.split("&");

    for (let i = 0; i < urlParams.length; i++) {
      let paramName = urlParams[i].split("=");

      if (paramName[0] === key) {
        return paramName[1] === undefined
          ? true
          : decodeURIComponent(paramName[1]);
      }
    }
  },
  getPathName() {
    return window.location.pathname;
  },
  replaceWithNepaliDigit(number) {
    const numbers = {
      '0': '०',
      '1': '१',
      '2': '२',
      '3': '३',
      '4': '४',
      '5': '५',
      '6': '६',
      '7': '७',
      '8': '८',
      '9': '९'
    };

    const arrNumNepali = Array.from(number.toString()).map(char => {
      if (isNaN(parseInt(char))) {
        return char;
      }
      return numbers[char];
    });

    return arrNumNepali.join('');
  }

};
