import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import { FISCAL_YEARS, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import styles from "./style";

export default function AdminSetting() {
  const classes = styles();

  const { register, setValue, handleSubmit } = useForm();

  const [defaultValues, setDefaultValues] = useState({});

  useEffect(() => {
    register({ name: "currentFiscalYear" });

  }, [register]);

  useEffect(() => {
    getApplicationSettingData();
  }, []);
  const getApplicationSettingData = () => {
    JA.get(API_URL.applicationSetting).then(response => {
      setDefaultValues(response.data);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    });
  }

  const handleFiscalYearChange = option => {
    setValue("currentFiscalYear", option);
  }

  const onSubmit = data => {
    data.id = defaultValues.id;
    JA.post(API_URL.applicationSetting, data).then(response => {
      AddAlertMessage({ type: response.data.type, message: response.data.message });
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    });
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h5">
          Admin Setting
        </Typography>
      </Box>
      <Grid container spacing={1} alignItems="center" className={classes.row}>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomSelect
          label="चालु आ.व."
          size="small"
          name="currentFiscalYear"
          options={FISCAL_YEARS}
          onChange={handleFiscalYearChange}
          value={defaultValues.currentFiscalYear}
          fullWidth
        />
        <Box
          item="true"
          textAlign="right"
          borderTop={1}
          borderColor={"grey.500"}
          pt={2}
          className={classes.btnContainer}
        >
          <Button
            className={classes.resetBtn}
            variant="contained"
            color="secondary"
            type="reset"
          >
            रद्द गर्नुहोस
            </Button>
          <Button variant="contained" color="primary" type="submit">
            सुरक्षित गर्नुहोस
            </Button>
        </Box>
      </form>
    </Container>
  );
}
