import { Avatar, Box, Button, Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import { AccountBalance, Add, Cached, DoneAll, HelpOutline, KeyboardArrowRightOutlined, NotInterested, VisibilityOff } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import DoughnutChart from "../../../components/charts/DoughnutChart";
import PieChart from "../../../components/charts/PieChart";
import ComplaintsList from "../../../components/complaints-list/ComplaintsList";
import EmptyContainer from "../../../components/empty-container/EmptyContainer";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { AppMisc } from "../../../misc/appMisc";
import { PALIKA_NAME, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { COMPLAINT_TYPES } from "../../../utils/constants/forms/index";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import styles from "./style";

export default function PalikaLandingPage() {
  const [complaintsList, setComplaintsList] = useState();
  const [complaintsCountByStatus, setComplaintsCountByStatus] = useState({});
  const [chartDataByComplaintCategory, setChartDataByComplaintCategory] = useState([]);
  const [chartLabelsByComplaintCategory, setChartLabelsByComplaintCategory] = useState([]);
  const [chartDataByStatus, setChartDataByStatus] = useState([]);
  const [chartLabelsByStatus, setChartLabelsByStatus] = useState([]);
  const palikaName = AppMisc.getPalikaNameFromHost();

  let history = useHistory();
  const classes = styles();

  useEffect(() => {
    getComplaints();
    getComplaintsCount();
    getComplaintsByCategory();
    SessionStorage.setItem(PALIKA_NAME, palikaName);
  }, []);

  const getComplaintsCount = () => {
    JA.get(API_URL.complaintDetail + "/complaints-count-by-status?palikaName=" + palikaName)
      .then(response => {
        setComplaintsCountByStatus(response.data);
        setChartDataByStatus(Object.values(response.data));
        setChartLabelsByStatus(Object.entries(response.data).map(([key, value]) => getComplaintStatusLabel(key)));
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const getComplaints = () => {
    // size param is used to return last n complaints created in the system
    JA.get(API_URL.complaintDetail + "?size=15&palikaName=" + palikaName)
      .then(response => {
        setComplaintsList(response.data);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  const getComplaintsByCategory = () => {
    JA.get(API_URL.complaintDetail + "/category?palikaName=" + palikaName)
      .then(response => {
        setChartDataByComplaintCategory(Object.values(response.data));
        setChartLabelsByComplaintCategory(Object.entries(response.data).map(([key, value]) => getComplaintCategoryLabel(key)));
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  function getNoRecordsMessage() {
    return (
      <EmptyContainer className={classes.fullWidth}>
        <Typography className={classes.emptyMsg}>
          माफ गर्नुहोस्, प्रणालीमा कुनै पनि रेकर्ड समावेश गरिएको छैन।
        </Typography>
      </EmptyContainer>
    )
  }

  function getComplaintCategoryLabel(complaintCategory) {
    return complaintCategory ? COMPLAINT_TYPES.find(obj => obj.value === complaintCategory).label : "";
  }

  function getComplaintStatusLabel(status) {
    switch (status) {
      case "UNDER_REVIEW":
        return "नहेरिएको";
      case "UNASSIGNED":
        return "स्वीकृत गरिएको";
      case "IN_PROGRESS":
        return "प्रशोधन/अनुसन्धान गरिदै";
      case "INAPPROPRIATE":
        return "अनुपयुक्त";
      case "CLOSED":
        return "समाधान भएको";
      default:
        return "कुल प्राप्त गुनासो";
    }
  }
  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <PublicHeader />
      <Box className={classNames(classes.banner, "gradient-primary")} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Box className={classes.bannerDetails} display="flex" alignItems="center" justifyContent="center">
          <Box className={classes.item}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar className={classes.light}>
                <AccountBalance />
              </Avatar>
            </Box>
            <Typography variant="h6">{complaintsCountByStatus.totalComplaints || 0}</Typography>
            <Typography>कुल प्राप्त गुनासो</Typography>
          </Box>
          <Box className={classes.item}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar className={classes.light}>
                <DoneAll />
              </Avatar>
            </Box>
            <Typography variant="h6">{complaintsCountByStatus.CLOSED || 0}</Typography>
            <Typography>समाधान भएको</Typography>
          </Box>
          <Box className={classes.item}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar className={classes.light}>
                <Cached />
              </Avatar>
            </Box>
            <Typography variant="h6">{complaintsCountByStatus.IN_PROGRESS || 0}</Typography>
            <Typography>प्रशोधन/अनुसन्धान गरिदै</Typography>
          </Box>
          <Box className={classes.item}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar className={classes.light}>
                <VisibilityOff />
              </Avatar>
            </Box>
            <Typography variant="h6">{complaintsCountByStatus.UNDER_REVIEW || 0}</Typography>
            <Typography>नहेरिएको</Typography>
          </Box>
          <Box className={classes.item}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar className={classes.light}>
                <NotInterested />
              </Avatar>
            </Box>
            <Typography variant="h6">{complaintsCountByStatus.INAPPROPRIATE || 0}</Typography>
            <Typography>अनुपयुक्त</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Button
            startIcon={<Add />}
            size="large"
            variant="outlined"
            className={classes.whiteButton}
            onClick={(e) => { history.push("/complaint-register"); }}
          >
            गुनासो / उजुरी दर्ता गर्नुहोस्
          </Button>
          <Box mx={2}></Box>
          <Button
            startIcon={<HelpOutline />}
            size="large"
            variant="outlined"
            className={classes.whiteButton}
            onClick={(e) => { history.push("/complaint-status"); }}
          >
            गुनासोको स्थिती थाहा पाउनुहोस्
          </Button>
        </Box>
      </Box>
      <Box my={3} pt={3} className={classes.publicComplaints}>
        <Box textAlign="center" mb={2}>
          <Typography className="border-bottom-heading">{AppMisc.getMunicipalityName(palikaName)} अन्तर्गत सार्वजनिक गरिएका सबै गुनासो/उजुरीहरु</Typography>
        </Box>
        <Box className={classes.publicComplaintsCardContainer}>
          <ComplaintsList complaints={complaintsList} />
        </Box>
        {complaintsList && complaintsList.length !== 0 &&
          <Box textAlign="center" mt={2}>
            <Button
              endIcon={<KeyboardArrowRightOutlined fontSize="small" />}
              color="primary"
              variant="contained"
              onClick={(e) => { history.push("/all-complaints"); }}
            >
              सबै गुनासो/उजुरीहरू हेर्नुहोस्
            </Button>
          </Box>
        }
      </Box>
      <Grid container className={classes.row} spacing={2}>
        <Grid item xs>
          <Card className={classes.chartContainer}>
            <CardContent>
              {
                chartDataByComplaintCategory.length ? (
                  <PieChart
                    labels={chartLabelsByComplaintCategory}
                    chartData={chartDataByComplaintCategory}
                    options={
                      {
                        title: {
                          display: true,
                          text: "धेरै गुनासो प्राप्त भएका गुनासो प्रकृतिहरु"
                        },
                        responsive: true,
                        tooltips: {
                          callbacks: {
                            title: function (tooltipItem, data) {
                              return data["labels"][tooltipItem[0]["index"]];
                            },
                            label: function (tooltipItem, data) {
                              return "जम्मा गुनासो/उजुरी संख्या: " + data["datasets"][0]["data"][tooltipItem["index"]] + " वटा";
                            },
                          },
                        },
                        legend: {
                          position: "bottom",
                          align: "middle",
                        },
                      }
                    }
                  />
                ) : (
                  getNoRecordsMessage()
                )
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.chartContainer}>
            <CardContent>
              {
                chartDataByStatus.length > 1 ? (
                  <DoughnutChart
                    labels={chartLabelsByStatus}
                    chartData={chartDataByStatus}
                    options={
                      {
                        title: {
                          display: true,
                          text: "जन-आवाज प्रणालीमा प्राप्त गुनासोको ताजा अवस्था"
                        },
                        responsive: true,
                        legend: {
                          position: "bottom",
                          align: "middle",
                        },
                      }
                    }
                  />
                ) : (
                  getNoRecordsMessage()
                )
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
