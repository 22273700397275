import { Box, Button, Checkbox, Chip, Container, FormControlLabel, FormLabel, Grid, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { AppMisc } from "../../../misc/appMisc";
import { AppUtils } from "../../../utils/appUtils";
import { ENTER_VALID_EMAIL, FILE_SIZE_EXCEED, GREATER_THAN_ONE, PALIKA_NAME, PROVINCE_DISTRICT_PALIKA_LIST, REQUIRED_FIELD, ROLE_USER, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { COMPLAINT_PRIORITY_TYPES, COMPLAINT_TYPES } from "../../../utils/constants/forms";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import DepartmentSelect from "../../user/components/complaints-table/DepartmentSelect/DepartmentSelect";
import styles from "./style";

export default function ComplaintRegister(props) {
  const classes = styles();
  const userRole = AppUtils.getUserRole();
  const [complaintTypeLabel, setComplaintTypeLabel] = useState();
  let palikaName = SessionStorage.getItem(PALIKA_NAME);
  const [districtOptions, setDistrictOptions] = useState();
  const [palikaOptions, setPalikaOptions] = useState();
  const [provinceLabel, setProvinceLabel] = useState();
  const [districtLabel, setDistrictLabel] = useState();
  const [palikaNameLabel, setPalikaNameLabel] = useState();
  const [provinceSelected, setProvinceSelected] = useState(false);
  const [districtSelected, setDistrictSelected] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [filesValid, setFilesValid] = useState(true);
  const [complaintSubmitted, setComplaintSubmitted] = useState(false);
  const [submittedTicketId, setSubmittedTicketId] = useState();
  const { register, errors, setValue, handleSubmit, reset, watch } = useForm();
  const watchField = watch(["palikaName"]);

  useEffect(() => {
    register({ name: "complaintType" }, { required: true });
    register({ name: "priority" }, { required: true });
    register({ name: "province" }, { required: true });
    register({ name: "district" }, { required: true });
    register({ name: "palikaName" }, { required: true });
    register({ name: "departments" });
    palikaName && setProvinceDistrictValues();
  }, [register]);

  function setProvinceDistrictValues() {
    let provinceDistrict = AppMisc.getProvinceDistrictFromMunicipalityValue(palikaName);
    setValue("province", provinceDistrict.province);
    setValue("district", provinceDistrict.district);
    setValue("palikaName", palikaName);
  }

  const handleComplaintTypeChange = complaintOption => {
    setValue("complaintType", complaintOption ? complaintOption.value : null);
    setComplaintTypeLabel(complaintOption ? complaintOption : null);
  };

  const handleProvinceChange = provinceOption => {
    provinceOption ? setProvinceSelected(true) : setProvinceSelected(false);

    setValue([
      { "province": provinceOption ? provinceOption.value : null },
      { "district": null },
      { "palikaName": null },
    ]);
    setProvinceLabel(provinceOption ? provinceOption : null);
    setDistrictLabel(null);
    setPalikaNameLabel(null);
    provinceOption &&
      setDistrictOptions(provinceOption.districts);
  }

  const handleDistrictChange = (districtOption) => {
    districtOption ? setDistrictSelected(true) : setDistrictSelected(false);
    setValue([
      { "palikaName": null },
      { "district": districtOption ? districtOption.value : null },
    ]);
    setPalikaNameLabel(null);
    setDistrictLabel(districtOption ? districtOption : "");
    districtOption &&
      setPalikaOptions(districtOption.palikas);
  }

  const handlePalikaNameChange = palikaOption => {
    setValue("palikaName", palikaOption ? palikaOption.value : null);
    setPalikaNameLabel(palikaOption ? palikaOption : "");
  }

  const handleCustomSelectChange = (value, name) => {
    setValue(name, value);
  };
  const handleDepartmentChange = (name, value) => {
    setValue(name, value);
  }

  const complaintSubmit = data => {
    const formData = new FormData();
    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }
    for (let [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    JA.post(API_URL.complaintDetail, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(response => {
        if (response.data.type === "success") {
          setComplaintSubmitted(true);
          setSubmittedTicketId(response.data.ticketId);
          reset();
        }
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
      })
      .catch(error => {
        setComplaintSubmitted(false);
        setSubmittedTicketId(null);
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  function handleDocumentsChange(e) {
    let files = e.target.files;
    let filesList = [];
    let allFileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i]);
        allFileSize += files[i].size;
      }
    }
    setFilesValid(allFileSize < (10 * 1024 * 1024))
    setAttachments(filesList);
  }

  return (
    <Container className={userRole !== ROLE_USER ? classes.root : ""} maxWidth={false} disableGutters>
      {userRole !== ROLE_USER && <PublicHeader />}
      <Container maxWidth={false} className={classes.complaintDetailsContainer} disableGutters>
        {
          complaintSubmitted ? (
            <Paper className={classes.ticketSubmittedContainer}>
              <Typography variant="h5">धन्यवाद! तपाइको उजूरी/गुनासो सफलतापूर्वक जन-आवाज प्रणालीमा दर्ता भएको छ।</Typography>
              <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                <Typography>कृपया आफ्नो गुनासोको स्थितिकाे बारेमा थप जान्नकाे लागि टिकट नम्बर</Typography>
                <Chip className={classes.ticketNumber} size="small" color="primary" label={submittedTicketId} />
                <Typography>प्रयोग गर्नुहाेस अथवा सम्बन्धित पालिकामा सम्पर्क राख्नुहोस।</Typography>
              </Box>
            </Paper>
          ) : (
            <Paper className={classes.formContainer}>
              <Box mb={1}>
                <Typography className="border-bottom-heading">गुनासो विवरण</Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" color="error">[ नोट: प्रभावकारी रूपमा गुनासो सम्बोधन गर्नका निम्ति, कृपया वास्तविक र विस्तृत जानकारी प्रदान गर्नुहोस् ]</Typography>
              </Box>
              <form onSubmit={handleSubmit(complaintSubmit)}>
                <Box className={classes.complaintSpecificDetails}>
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    <Grid item xs={4}>
                      <Select
                        className="select-sm"
                        classNamePrefix="react-select"
                        placeholder="कृपया, गुनासोको प्रकार चयन गर्नुहोस्।"
                        name="complaintType"
                        size="small"
                        variant="outlined"
                        options={COMPLAINT_TYPES}
                        value={complaintTypeLabel}
                        onChange={handleComplaintTypeChange}
                        isClearable
                      />
                      {errors.complaintType && <span className="error-message">{REQUIRED_FIELD}</span>}
                    </Grid>
                    <Grid item xs={4}>
                      <Tooltip title="समस्या सिर्जनाकर्ता व्यक्ति भए व्यक्तिको नाम अथवा कार्यालय भए कार्यालयको नाम उल्लेख गर्नुहोस्।" placement="top" arrow>
                        <TextField
                          label="समस्या सिर्जनाकर्ताको नाम"
                          type="text"
                          size="small"
                          variant="outlined"
                          name="problemCreatorName"
                          inputRef={register({
                            required: true
                          })}
                          fullWidth
                        />
                      </Tooltip>
                      {errors.problemCreatorName && <span className="error-message">{REQUIRED_FIELD}</span>}
                    </Grid>
                    <Grid item xs={4}>
                      <CustomSelect
                        label="गुनासोको गम्भीरता"
                        size="small"
                        name="priority"
                        variant="outlined"
                        options={COMPLAINT_PRIORITY_TYPES}
                        onChange={handleCustomSelectChange}
                        disabledOptionSelectable
                        fullWidth
                      />
                      {errors.priority && <span className="error-message">{REQUIRED_FIELD}</span>}
                    </Grid>
                    {
                      !palikaName &&
                      <>
                        <Grid item xs={4}>
                          <Select
                            className="select-sm"
                            classNamePrefix="react-select"
                            placeholder="प्रदेशको नाम"
                            name="province"
                            size="small"
                            variant="outlined"
                            options={PROVINCE_DISTRICT_PALIKA_LIST}
                            value={provinceLabel}
                            onChange={handleProvinceChange}
                            isClearable
                          />
                          {errors.province && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            className="select-sm"
                            classNamePrefix="react-select"
                            placeholder="जिल्लाको नाम"
                            name="district"
                            size="small"
                            variant="outlined"
                            options={districtOptions}
                            value={districtLabel}
                            onChange={handleDistrictChange}
                            isDisabled={!provinceSelected}
                            isClearable
                          />
                          {errors.district && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                        <Grid item xs={4}>
                          <Select
                            className="select-sm"
                            classNamePrefix="react-select"
                            placeholder="पालिकाको नाम"
                            name="palikaName"
                            size="small"
                            variant="outlined"
                            options={palikaOptions}
                            value={palikaNameLabel}
                            onChange={handlePalikaNameChange}
                            isDisabled={!districtSelected || !provinceSelected}
                            isClearable
                          />
                          {errors.palikaName && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                      </>
                    }
                  </Grid>
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    <Grid item xs={4}>
                      <TextField
                        label="वडा नं."
                        type="number"
                        size="small"
                        variant="outlined"
                        name="wardNumber"
                        inputRef={register({
                          required: true,
                          min: 1
                        })}
                        fullWidth
                      />
                      {errors.wardNumber && errors.wardNumber.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
                      {errors.wardNumber && errors.wardNumber.type === "min" && (<span className="error-message">{GREATER_THAN_ONE}</span>)}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="टोल/स्थान"
                        type="text"
                        size="small"
                        variant="outlined"
                        name="address"
                        inputRef={register({
                          required: true
                        })}
                        fullWidth
                      />
                      {errors.address && <span className="error-message">{REQUIRED_FIELD}</span>}
                    </Grid>
                    <Grid item xs={4}>
                      <DepartmentSelect
                        placeholder="गुनासो दर्ता गर्नुपर्ने शाखा／कार्यालय"
                        onChange={handleDepartmentChange}
                        palikaName={palikaName || watchField.palikaName}
                        isMulti
                        creatable
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    <Grid item xs>
                      <TextField
                        label="गुनासो/उजुरीको संक्षिप्त विवरण"
                        type="text"
                        size="small"
                        rows={5}
                        variant="outlined"
                        name="complaintDetails"
                        inputRef={register({
                          required: true
                        })}
                        fullWidth
                        multiline
                      />
                      {errors.complaintDetails && <span className="error-message">{REQUIRED_FIELD}</span>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    <Grid item xs={12}>
                      <Box mb={1}>
                        <FormLabel component="legend">सम्वन्धित कागजातहरु (छ भने)</FormLabel>
                        <Typography variant="caption" color="error">नोट: कृपया फाईल चयन गर्दा 10MB भन्दा कम साइजको फाइल चयन गर्नहुन अनुरोध छ।</Typography>
                      </Box>
                      <Box className={classes.filesContainer}>
                        <input
                          type="file"
                          name="documents"
                          accept="image/*,application/pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls"
                          onChange={handleDocumentsChange}
                          multiple
                        />
                      </Box>
                      {!filesValid && <span className="error-message">{FILE_SIZE_EXCEED}</span>}
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.userDetails}>
                  <Box mb={2}>
                    <Typography className="border-bottom-heading">व्यक्तिगत विवरण</Typography>
                  </Box>
                  <Grid container spacing={2} alignItems="center" className={classes.row}>
                    {
                      userRole !== ROLE_USER &&
                      <>
                        <Grid item xs>
                          <TextField
                            label="पुरा नाम"
                            type="text"
                            size="small"
                            variant="outlined"
                            name="reporterFullName"
                            inputRef={register({
                              required: true
                            })}
                            fullWidth
                          />
                          {errors.reporterFullName && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                        <Grid item xs>
                          <TextField
                            label="ठेगाना"
                            type="text"
                            size="small"
                            variant="outlined"
                            name="reporterAddress"
                            inputRef={register({
                              required: true
                            })}
                            fullWidth
                          />
                          {errors.reporterAddress && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                        <Grid item xs>
                          <TextField
                            label="मोबाइल नम्बर"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="reporterPhone"
                            inputRef={register({
                              required: true
                            })}
                            fullWidth
                          />
                          {errors.reporterPhone && <span className="error-message">{REQUIRED_FIELD}</span>}
                        </Grid>
                        <Grid item xs>
                          <TextField
                            label="सम्पर्क ईमेल"
                            type="email"
                            size="small"
                            variant="outlined"
                            name="reporterEmail"
                            inputRef={register({
                              required: true,
                              pattern: /\S+@\S+\.\S+/
                            })}
                            fullWidth
                          />
                          {errors.reporterEmail && errors.reporterEmail.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
                          {errors.reporterEmail && errors.reporterEmail.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
                        </Grid>
                      </>
                    }
                    <Grid item xs>
                      <Tooltip title="व्यक्तिगत विवरण गोप्य राख्न चाहनुहुन्छ भने, यसमा चेक (✓) लगाउनुहोस्।" placement="top" arrow>
                        <FormControlLabel
                          control={<Checkbox color="primary" />}
                          label="गोप्य राख्नुहोस्"
                          name="private"
                          inputRef={register}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  item="true"
                  textAlign="right"
                  borderTop={1}
                  borderColor={"grey.500"}
                  pt={2}
                  className={classes.btnContainer}
                >
                  <Button
                    className={classes.resetBtn}
                    variant="contained"
                    color="secondary"
                    type="reset"
                  >
                    रद्द गर्नुहोस
                  </Button>
                  <Button disabled={!filesValid} variant="contained" color="primary" type="submit">
                    सुरक्षित गर्नुहोस
                  </Button>
                </Box>
              </form>
            </Paper>
          )
        }
      </Container>
    </Container>
  );
}
