import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(.5),
  },
  label: {
    marginRight: theme.spacing(1),
  },
  value: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    wordBreak: "break-word",
  },
  complaintHistoryTableContainer: {
    marginBottom: theme.spacing(2)
  },
  gridRow: {
    marginBottom: theme.spacing(1),
  },
  helpIcon: {
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5),
    color: theme.palette.text.secondary,
  },
  documentRow: {
    padding: 0
  },
  item: {
    fontSize: "12px",
    paddingLeft: 0
  },
  tableBodyRow: {
    "& .MuiTableCell-root": {
      wordBreak: "break-word"
    }
  }
}));
export default styles;
