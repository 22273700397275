import { Box, Button, Card, CardContent, Container, Grid, Link, TextField, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import JA, { API_URL } from "../../../api/api";
import logo from "../../../assets/img/logo.png";
import AddAlertMessage from "../../../components/alert/Alert";
import Spinner from "../../../components/loader/Loader";
import { AppMisc } from "../../../misc/appMisc";
import { ENTER_VALID_EMAIL, ERROR, PALIKA_NAME, PHONE_NUMBER_VALIDATION_ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants/index";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import styles from "./style";

export default function SignUp(props) {
  const classes = styles();
  const { register, handleSubmit, errors } = useForm();
  let palikaName = SessionStorage.getItem(PALIKA_NAME);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = data => {
    setIsLoading(true);
    JA.post(API_URL.signup, data)
      .then(response => {
        setIsLoading(false);
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          props.history.push("/");
        }
      })
      .catch(error => {
        setIsLoading(false);
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box textAlign="center" my={3}>
        <img src={logo} alt="गुनासो व्यवस्थापन प्रणाली" width="124" />
        <Box fontSize="h5.fontSize">जन-आवाज</Box>
        <Typography>{palikaName ? AppMisc.getMunicipalityName(palikaName) + " - " : ""}गुनासो व्यवस्थापन प्रणाली</Typography>
      </Box>
      <Card>
        <CardContent>
          <Typography variant="subtitle2" className={classes.heading}>गुनासो व्यवस्थापन प्रणालीको लागि नयाँ खाता सिर्जना गर्नुहोस् ।</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="fullName"
                label="पुरा नाम"
                margin="normal"
                variant="outlined"
                name="fullName"
                size="small"
                inputRef={register({
                  required: true
                })}
              />
              {errors.fullName && (<span className="error-message">{REQUIRED_FIELD}</span>)}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="userEmail"
                label="ईमेल"
                margin="normal"
                size="small"
                variant="outlined"
                name="userEmail"
                inputRef={register({
                  required: true,
                  pattern: /\S+@\S+\.\S+/
                })}
              />
              {errors.userEmail && errors.userEmail.type === "required" && (<span className="error-message">{REQUIRED_FIELD}</span>)}
              {errors.userEmail && errors.userEmail.type === "pattern" && (<span className="error-message">{ENTER_VALID_EMAIL}</span>)}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="ठेगाना"
                type="text"
                size="small"
                margin="normal"
                variant="outlined"
                name="address"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.address && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="मोबाइल नम्बर"
                type="number"
                margin="normal"
                size="small"
                variant="outlined"
                name="phoneNumber"
                inputRef={register({
                  required: true,
                  pattern: /^([0-9\u0966-\u096F]{10})$/
                })}
                fullWidth
              />
              {errors.phoneNumber?.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
              {errors.phoneNumber?.type === "pattern" && <span className="error-message">{PHONE_NUMBER_VALIDATION_ERROR}</span>}
            </Grid>
            <Grid item xs={12} className={classes.signUpBtnContainer}>
              {isLoading ? (
                <Spinner />
              ) : (
                <Button
                  endIcon={<ExitToAppIcon />}
                  size="large"
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Create My Account
                </Button>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" pt={1}>
        <Link
          href={AppMisc.getHomePageURLFromPalikaName(palikaName)}
          underline="none"
        >
          वेबसाइट गृहपृष्ठमा जानुहोस्
        </Link>
        <Link
          href="/login"
          underline="none"
          color="textSecondary"
          className={classes["forget-password-label"]}
        >
          लग-इन गर्नुहोस्
        </Link>
      </Box>
    </Container>
  );
}
