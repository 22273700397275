import { Box, Button, Chip, IconButton, Link, List, ListItem, ListItemSecondaryAction, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Add, Autorenew, Delete, DoneAll, DoneAllOutlined, Edit, NotInterested, Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import JA, { API_URL } from "../../api/api";
import { AppMisc } from "../../misc/appMisc";
import { AppUtils } from "../../utils/appUtils";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ID, NO_RECORDS_FOUND, ROLE_MUNICIPALITY_ADMIN, ROLE_MUNICIPALITY_REPRESENTATIVE, ROLE_WARD_REPRESENTATIVE, SOMETHING_WENT_WRONG } from "../../utils/constants";
import { COMPLAINT_STATUS } from "../../utils/constants/forms/index";
import { DateUtils } from "../../utils/dateUtils";
import AddAlertMessage from "../alert/Alert";
import CustomModal from "../modal/CustomModal";
import UpdateComplaintStatusModal from "./helpers/UpdateComplaintStatusModal";
import styles from "./style";

export default function ComplaintDetails(props) {
  const classes = styles();
  const [showUpdateComplaintStatusModal, setShowUpdateComplaintStatusModal] = useState(false);
  const [updateComplaintStatusModalDefaultValues, setUpdateComplaintStatusModalDefaultValues] = useState({});
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [documentToBeDeleteInfo, setDocumentToBeDeleteInfo] = useState({
    statusInfoId: null,
    fileName: null,
    filePath: null
  });
  const complaintId = AppUtils.getUrlParam(ID);
  const role = AppUtils.getUserRole();
  const allowedToUpdate = (role === ROLE_MUNICIPALITY_ADMIN || role === ROLE_MUNICIPALITY_REPRESENTATIVE || role === ROLE_WARD_REPRESENTATIVE)

  function getComplaintStatusLabel(status) {
    return status ? COMPLAINT_STATUS.find(obj => obj.value === status).label : "Unseen";
  }

  function getStatusIcon(status) {
    switch (status) {
      case "UNDER_REVIEW":
        return <Visibility fontSize="small" />;
      case 'UNASSIGNED':
        return <DoneAllOutlined fontSize="small" />;
      case 'IN_PROGRESS':
        return <Autorenew fontSize="small" />;
      case 'INAPPROPRIATE':
        return <NotInterested fontSize="small" />;
      case 'CLOSED':
        return <DoneAll fontSize="small" />;
      default:
        return <VisibilityOff fontSize="small" />;
    }
  }

  const handleUpdateComplaintModalClose = () => {
    setShowUpdateComplaintStatusModal(false);
    setUpdateComplaintStatusModalDefaultValues({});
  }

  const handleComplaintEdit = id => {
    JA.get(API_URL.complaintStatus + "/" + id).then(response => {
      setUpdateComplaintStatusModalDefaultValues(response.data);
      setShowUpdateComplaintStatusModal(true);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    });
  }

  const handleUpdateComplaintSubmit = data => {
    const formData = new FormData();
    let attachments = data.attachments;

    formData.append("complaintId", complaintId);
    if (updateComplaintStatusModalDefaultValues.id) {
      formData.append("id", updateComplaintStatusModalDefaultValues.id);
    }

    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }
    for (let [key, value] of Object.entries(data)) {
      key !== "attachments" &&
        formData.append(key, value);
    }

    JA.post(API_URL.complaintStatus, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(response => {
        if (response.data.type === "success") {
          handleUpdateComplaintModalClose();
          props.getComplaintDetails();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  const handleDocumentDelete = (fileDetails, id) => {
    setDocumentToBeDeleteInfo({
      statusInfoId: id,
      fileName: fileDetails.name,
      filePath: fileDetails.path
    });
    setShowDeleteConfirmModal(true);

  }

  const deleteDocument = () => {
    setShowDeleteConfirmModal(false);
    JA.post(API_URL.complaintStatus + "/delete", documentToBeDeleteInfo).then(response => {
      if (response.data.type === "success") {
        props.getComplaintDetails();
      }
      AddAlertMessage({ type: response.data.type, message: response.data.message });
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    })
  }
  const attachFiles = (documents, id) => {
    var documentsList = [];
    for (let i = 0; i < documents.length; i++) {
      let fileName = documents[i].name;
      documentsList.push(
        <ListItem className={classes.item} key={i}>
          <Link
            href={documents[i].path + fileName}
            target="_blank"
            className={classes.documentLink}
          >
            {fileName ? fileName.split(/_(.+)/)[1] : "-"}
          </Link>
          {id && allowedToUpdate &&
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => { handleDocumentDelete(documents[i], id) }}
                className={classes.deleteDocumentIcon}
                color="secondary"
              >
                <Delete fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          }
        </ListItem>
      )
    }
    return (
      <>
        <Box className={classes.documentsList}>
          {documentsList.length ? <List className={classes.documentRow}>{documentsList}</List> : "-"}
        </Box>
      </>
    )
  }

  return (
    <>
      <Box className={classes.row}>
        <Typography className={classes.label}>टिकट नम्बर:</Typography>
        <Typography className={classes.value}>{props.data.ticketId}</Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>गुनासोको प्रकार:</Typography>
        <Typography className={classes.value}>{AppMisc.getComplaintTypeLabel(props.data.complaintType)}</Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>गुनासो प्राप्त भएको मिति:</Typography>
        <Typography className={classes.value}>{AppUtils.replaceWithNepaliDigit(DateUtils.getDateFromMilliseconds(props.data.createdDate))}</Typography>
      </Box>
      {
        props.data.departments &&
        <Box className={classes.row}>
          <Typography className={classes.label}>गुनासो दर्ता गरिएको शाखा／कार्यालय:</Typography>
          <Typography className={classes.value}>{props.data.departments.join(", ")}</Typography>
        </Box>
      }
      <Box className={classes.row}>
        <Typography className={classes.label}>निवेदकको नाम र ठेगाना:</Typography>
        <Typography className={classes.value}>
          {
            allowedToUpdate ? (
              props.data.reporterFullName + ", " + AppMisc.getMunicipalityName(props.data.palikaName) + "-" + props.data.wardNumber + ", " + props.data.reporterAddress
            ) : (
              props.data.private ? "गाेप्य" : props.data.reporterFullName + ", " + AppMisc.getMunicipalityName(props.data.palikaName)
            )
          }
        </Typography>
      </Box>
      {
        allowedToUpdate &&
        <Box className={classes.row}>
          <Typography className={classes.label}>निवेदकको ईमेल र फोन नं.:</Typography>
          <Typography className={classes.value}>{props.data.reporterEmail + ", " + props.data.reporterPhone}</Typography>
        </Box>
      }
      <Box className={classes.row}>
        <Typography className={classes.label}>स्थिति:</Typography>
        <Chip
          icon={getStatusIcon(props.data.status)}
          label={getComplaintStatusLabel(props.data.status)}
          color="primary"
          variant="outlined"
        />
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>गम्भीरता:</Typography>
        <Typography className={classes.value}>{AppMisc.getComplaintPriorityLabel(props.data.priority)}</Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>गुनासो/उजुरीको विवरण	:</Typography>
        <Typography className={classes.value}>{props.data.complaintDetails}</Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>फाइल/कागजातहरु:</Typography>
        <Box className={classes.value}>{props.data.complaintDocuments && attachFiles(props.data.complaintDocuments)}</Box>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.label}>कार्यहरू:</Typography>
      </Box>
      <Box className={classes.complaintHistory}>
        <TableContainer className={classes.complaintHistoryTableContainer}>
          <Table className="table-bordered" size="small" aria-label="Complaint Details table">
            <TableHead>
              <TableRow>
                <TableCell>शीर्षक</TableCell>
                <TableCell>विवरण</TableCell>
                <TableCell>सम्वन्धित कागजातहरु</TableCell>
                <TableCell>मिति</TableCell>
                {
                  allowedToUpdate && props.data.complaintStatusList && <TableCell>सम्पादन</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                props.data.complaintStatusList &&
                  props.data.complaintStatusList.length ? (
                  props.data.complaintStatusList.map(row => (
                    <TableRow key={row.id} className={classes.tableBodyRow}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.documents ? attachFiles(row.documents, row.id) : "-"}</TableCell>
                      <TableCell>{AppUtils.replaceWithNepaliDigit(DateUtils.getDateFromMilliseconds(row.date))}</TableCell>
                      {allowedToUpdate &&
                        <TableCell>
                          <IconButton onClick={() => handleComplaintEdit(row.id)} aria-label="edit">
                            <Edit fontSize="small" />
                          </IconButton>
                        </TableCell>
                      }
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          allowedToUpdate &&
          <Box align="center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => {
                setShowUpdateComplaintStatusModal(true);
              }}
            >
              गुनासोको कार्य विवरण थप्नुहोस्
            </Button>
          </Box>
        }
      </Box>
      <CustomModal
        title="फाइल हटाउनुहोस्।"
        onModalSubmit={() => { deleteDocument() }}
        showModal={showDeleteConfirmModal}
        onModalClose={() => setShowDeleteConfirmModal(false)}
        submitButtonText="हटाउनुहोस्"
      >
        <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित फाइल हटाउन चाहनुहुन्छ?</Typography>
        <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
      </CustomModal>

      <UpdateComplaintStatusModal defaultValues={updateComplaintStatusModalDefaultValues} showUpdateComplaintStatusModal={showUpdateComplaintStatusModal} onModalClose={handleUpdateComplaintModalClose} onSubmit={handleUpdateComplaintSubmit} />
    </>
  );
}
