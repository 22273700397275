import { Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import PublicHeader from "../../../components/public-header/PublicHeader";
import styles from "./style";

export default function LoginForm(props) {
  const classes = styles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <PublicHeader />
      <Typography>बारम्बार सोधिने प्रश्नहरु</Typography>
      <Paper className={classes.faq}>
        <Typography className={classes.question}>१. के गुनासो दर्ता गर्न म आफु रजिस्टर्ड हुनु पर्छ?</Typography>
        <Typography className={classes.answer}>पर्दैन, तपाईले "गुनासो/उजुरी दर्ता गर्नुहोस" लिंकमा क्लिक गरी आफ्नो गुनासो दर्ता फारम भर्न सक्नु हुन्छ।</Typography>
        <Typography className={classes.question}>२. गुनासो दर्ता गर्दा मैले कुनै सम्बन्धित फाइल पठाउन सक्छु?</Typography>
        <Typography className={classes.answer}>तपाईले आफुले चाहेजति फाइल पठाउन सक्नु हुन्छ तर फाइल १० एम.बी भन्दा बढी हुनुहुदैन।</Typography>
        <Typography className={classes.question}>३.कस्ता प्रकारका फाइलहरु पठाउन सकिन्छ?</Typography>
        <Typography className={classes.answer}>तपाईले फोटो, पि.डी.एफ फाइल वा डकुमेन्ट फाइल पठाउन सक्नुहुन्छ।</Typography>
        <Typography className={classes.question}>४. मेरो व्यक्तिगत बिवरण कसले हेर्न सक्छ?</Typography>
        <Typography className={classes.answer}>तपाईको व्यक्तिगत बिवरण सम्बन्धित पालिकाको अधिकार प्राप्त पधादिकारीले मात्र हेर्न सक्नेछन्।</Typography>
        <Typography className={classes.question}>५. मैले मेरो व्यक्तिगत बिवरण किन भर्नुपर्दछ?</Typography>
        <Typography className={classes.answer}>साधारणतया, तपाईको व्यक्तिगत बिवरण महत्वपूर्ण छैन तर तपाईको गुनासोको बारेमा थप जानकारी लिन वा तपाईसंग सम्पर्क गर्न पालिकाको अधिकार प्राप्त कर्मचारीलाई आवश्यक पर्नसक्दछ।</Typography>
        <Typography className={classes.question}>६. मैले आफ्नो गुनासोको नतिजा कसरी थाहापाउन सक्छु?</Typography>
        <Typography className={classes.answer}>तपाईले आफ्नो नयाँ गुनासो दर्ता गर्दा यस प्रणालीले तपाईलाई दर्ता नम्बर दिनेछ र सो दर्ता नम्बरको सहयोगले तपाई आफ्नो गुनासोको नतिजा हेर्न र बुझ्न सक्नुहुनेछ।</Typography>
        <Typography className={classes.question}>७. यदि मैले मेरो गुनासो/उजुरी को दर्ता नम्बर हराए वा बिर्सेको खण्डमा के गर्ने?</Typography>
        <Typography className={classes.answer}>तपाईले गुनासो दर्ता नम्बर पुन: प्राप्त गर्न सक्नु हुनेछैन तसर्थ तपाईले आफ्नो गुनसो/उजुरी को दर्ता नम्बर सुरक्षित राख्नु पर्ने हुनेछ । हामीले गुनसो दर्ता क्रममा तपाईलाई इमेलमा पनि दर्ता नम्बर पठाउने गर्दछौ।</Typography>
        <Typography className={classes.question}>८. के मलाई हरेक गुनसोको छुट्टाछुट्टै दर्ता नम्बर दिईनेछ?</Typography>
        <Typography className={classes.answer}>हो, हामी तपाईलाई हरेक गुनसो दर्ता गर्दा छुट्टाछुट्टै दर्ता नम्बर प्रदान गर्दछौं।</Typography>
      </Paper>
    </Container>
  );
}
