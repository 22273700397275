import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  row: {
    marginBottom: theme.spacing(1)
  },
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  },
  filesContainer: {
    marginBottom: theme.spacing(.5)
  },
  ticketSubmittedContainer: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  ticketNumber: {
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5)
  }
}));
export default styles;