
import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddUserModal from "../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../components/alert/Alert";
import UserList from "../../../components/user-list/UserList";
import { AppUtils } from "../../../utils/appUtils";
import { ADD_USER_ALLOWED_ROLES_FOR_ADMIN, ID, SOMETHING_WENT_WRONG } from "../../../utils/constants";

export default function MunicipalityHome(props) {
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userTableData, setUserTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  }

  const handleAddUserModalSubmit = data => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      data.palikaId = palikaId;
      if (modalDefaultValues.id) {
        data.id = modalDefaultValues.id;
      }
      JA.post(API_URL.user, data).then(response => {
        if (response.data.type === "success") {
          handleAddUserModalClose(false);
          getMunicipalityUserData();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message })
      }).catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG })
      });
    }
  }

  useEffect(() => {
    getMunicipalityUserData();
  }, []);

  const getMunicipalityUserData = () => {
    let palikaId = AppUtils.getUrlParam(ID);
    if (palikaId) {
      JA.get(API_URL.palikaUsers + "/?palikaId=" + palikaId).then(response => {
        setUserTableData(response.data);
      }).catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
    }
  };

  const editUserDetails = id => {
    JA.get(API_URL.user + "/palika-user/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setOpenAddUserModal(true);
      }).catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
        <Typography variant="h5">प्रयोगकर्ताहरू</Typography>
        <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddUserModal(true) }}>नयाँ प्रयोगकर्ता थप्नुहोस्</Button>
      </Box>
      <Box>
        <UserList tableData={userTableData} showActionColumn={userTableData.length !== 0} onEditRow={editUserDetails} />
      </Box>
      <AddUserModal modalDefaultValues={modalDefaultValues} userRoles={ADD_USER_ALLOWED_ROLES_FOR_ADMIN} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={handleAddUserModalSubmit.bind(this)} />
    </>
  )
}