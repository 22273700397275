import { Box, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import ComplaintsList from "../../../../components/complaints-list/ComplaintsList";
import { ROLE_WARD_REPRESENTATIVE, SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import styles from "../style";

export default function Representative(props) {
  const classes = styles();
  const [complaints, setComplaints] = useState([]);

  useEffect(() => {
    getComplaints();
  }, []);

  const getComplaints = () => {
    let getComplaintsPath = "complaint-by-palika-name";
    if (props.role === ROLE_WARD_REPRESENTATIVE) {
      getComplaintsPath = "complaints-by-ward-number"
    }
    JA.get(API_URL.complaintDetail + "/" + getComplaintsPath).then(response => {
      setComplaints(response.data.objectList);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    })
  }

  return (
    <>
      <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
        <Typography variant="h6">तपाईंको {props.role === ROLE_WARD_REPRESENTATIVE ? "वडा" : "पालिका"} मा दर्ता गरिएका गुनासो/उजुरीहरु</Typography>
        <Tooltip title="यी सर्वसाधारणले दर्ता गरेका गुनासोहरू हुन्। कृपया गुनासो/उजूरी विवरण हेर्नुहोस् र उपयुक्त प्रक्रियाहरूको साथ अघि बढ्नुहोस्।" placement="top" arrow>
          <Help className={classes.helpIcon} fontSize="small" />
        </Tooltip>
      </Box>
      <Box className={classes.complaintsContainer}>
        <ComplaintsList complaints={complaints} />
      </Box>
    </>
  );
}
