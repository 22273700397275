import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";
import { AppMisc } from "../../misc/appMisc";
import { NO_RECORDS_FOUND } from "../../utils/constants";


export default function UserList({ onEditRow, ...props }) {
  return (
    <TableContainer>
      <Table classes={{ root: "table-bordered" }} size="small" aria-label="Municipality Users">
        <TableHead classes={{ root: "align-center" }}>
          <TableRow>
            <TableCell>नाम</TableCell>
            <TableCell>ईमेल</TableCell>
            <TableCell>भूमिका</TableCell>
            {props.showActionColumn && <TableCell>सम्पादन</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: "align-center" }}>
          {props.tableData.length === 0 ?
            (
              <TableRow>
                <TableCell colSpan={3} align="center" size="medium">{NO_RECORDS_FOUND}</TableCell>
              </TableRow>
            ) : (
              props.tableData.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.fullName}</TableCell>
                  <TableCell>{row.userEmail}</TableCell>
                  <TableCell>{AppMisc.getUserRole(row.role)}</TableCell>
                  {props.showActionColumn &&
                    <TableCell>
                      <IconButton onClick={() => onEditRow(row.id)} aria-label="edit">
                        <Edit fontSize="small" />
                      </IconButton>
                    </TableCell>
                  }
                </TableRow>
              ))
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}