import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DepartmentSelect from "../../pages/user/components/complaints-table/DepartmentSelect/DepartmentSelect";
import { ENTER_VALID_EMAIL, GREATER_THAN_ONE, REQUIRED_FIELD, ROLE_WARD_REPRESENTATIVE, USER_ROLE } from "../../utils/constants";
import CustomSelect from "../custom-select/CustomSelect";
import CustomModal from "../modal/CustomModal";
import styles from "./style";

export default function AddUserModal({ showAddUserModal, handleAddUserModalClose, onSubmit, ...props }) {
  const classes = styles();
  const [isWardRepresentative, setIsWardRepresentative] = useState(false);
  const { register, setValue, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    register({ name: "role" }, { required: true });
    register({ name: "departments" });
  }, [register]);

  const handleCustomSelectChange = (value, name) => {
    setValue(name, value);
    if (name === USER_ROLE) {
      setIsWardRepresentative(value === ROLE_WARD_REPRESENTATIVE);
    }
  };

  const handleDepartmentChange = (name, value) => {
    setValue(name, value);
  }

  function closeAddUserModal() {
    reset();
    setIsWardRepresentative(false);
    handleAddUserModalClose();
  }

  return (
    <>
      <CustomModal
        title="नयाँ प्रयोगकर्ता थप्नुहोस्"
        onModalSubmit={handleSubmit(onSubmit)}
        showModal={showAddUserModal}
        onModalClose={closeAddUserModal}
        fullScreen
      >
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              required
              label="User Full Name"
              type="text"
              variant="outlined"
              defaultValue={props.modalDefaultValues.fullName}
              size="small"
              name="fullName"
              inputRef={register({
                required: true
              })}
            />
            {errors.fullName && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              required
              label="User Email"
              type="email"
              size="small"
              defaultValue={props.modalDefaultValues.userEmail}
              variant="outlined"
              name="userEmail"
              inputRef={register({
                required: true,
                pattern: /\S+@\S+\.\S+/
              })}
            />
            {errors.userEmail && errors.userEmail.type === "required" && <span className="error-message">{REQUIRED_FIELD}</span>}
            {errors.userEmail && errors.userEmail.type === "pattern" && <span className="error-message">{ENTER_VALID_EMAIL}</span>}
          </Grid>
          <Grid item xs>
            <CustomSelect
              label="प्रयोगकर्ताको भूमिका"
              size="small"
              name="role"
              variant="outlined"
              value={props.modalDefaultValues.role}
              options={props.userRoles}
              onChange={handleCustomSelectChange}
              disabledOptionSelectable
              fullWidth
            />
            {errors.role && <span className="error-message">{REQUIRED_FIELD}</span>}
          </Grid>
          {
            isWardRepresentative &&
            <Grid item xs>
              <TextField
                fullWidth
                label="वार्ड नम्बर"
                type="number"
                variant="outlined"
                size="small"
                name="wardNumber"
                defaultValue={props.modalDefaultValues.wardNumber}
                inputRef={register({
                  min: 1
                })}
              />
              {errors.wardNumber && (<span className="error-message">{GREATER_THAN_ONE}</span>)}
            </Grid>
          }
        </Grid>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs>
            <DepartmentSelect
              placeholder="प्रयोगकर्ताको शाखा／कार्यालय"
              onChange={handleDepartmentChange}
              isMulti
              creatable
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              inputRef={register}
              control={
                <Switch color="primary" defaultChecked={props.modalDefaultValues.isActive} name="isActive" />
              }
              label="सक्रिय / निष्क्रिय"
              classes={{
                label: classes.switchLabelSmall,
              }}
            />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </CustomModal>
    </>
  );
};