import { Box, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../../api/api";
import AddAlertMessage from "../../../../components/alert/Alert";
import { SOMETHING_WENT_WRONG } from "../../../../utils/constants";
import { COMPLAINTS_TABLE_COMMON_HEAD_ITEMS } from "../../../../utils/constants/forms";
import ComplaintsTable from "../../components/complaints-table/ComplaintsTable";
import styles from "../style";

const columns = COMPLAINTS_TABLE_COMMON_HEAD_ITEMS;

export default function MunicipalityAdmin() {
  const classes = styles();
  const [underReviewComplaint, setUnderReviewComplaint] = useState([]);
  const [unassignedComplaint, setUnassignedComplaint] = useState([]);
  const [inProgressComplaint, setInProgressComplaint] = useState([]);
  const [inappropriateComplaint, setInappropriateComplaint] = useState([]);
  const [closedComplaint, setClosedComplaint] = useState([]);

  useEffect(() => {
    getComplaintByStatus();
  }, []);

  const getComplaintByStatus = () => {
    JA.get(API_URL.complaintDetail + "/complaints-by-status").then(response => {
      response.data.UNDER_REVIEW && setUnderReviewComplaint(response.data.UNDER_REVIEW);
      response.data.UNASSIGNED && setUnassignedComplaint(response.data.UNASSIGNED);
      response.data.IN_PROGRESS && setInProgressComplaint(response.data.IN_PROGRESS);
      response.data.CLOSED && setClosedComplaint(response.data.CLOSED);
      response.data.INAPPROPRIATE && setInappropriateComplaint(response.data.INAPPROPRIATE);
    }).catch(error => {
      AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
    })
  }

  return (
    <>
      <Box mb={3}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6">अनुमोदन गर्न बाँकी गुनासोहरू</Typography>
          <Tooltip title="यी सर्वसाधारणले दर्ता गरेका गुनासोहरू हुन्। कृपया गुनासो/उजूरी विवरण हेर्नुहोस् र उपयुक्त प्रक्रियाहरूको साथ अघि बढ्नुहोस्।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <ComplaintsTable rows={underReviewComplaint} columns={columns} />
      </Box>
      <Box mb={3}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6">स्वीकृत गरिएका गुनासोहरू</Typography>
          <Tooltip title="यी सर्वसाधारणले दर्ता गरेका गुनासोहरू हुन्। कृपया गुनासो/उजूरी विवरण हेर्नुहोस् र उपयुक्त प्रक्रियाहरूको साथ अघि बढ्नुहोस्।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <ComplaintsTable rows={unassignedComplaint} columns={columns} />
      </Box>
      <Box mb={3}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6">प्रक्रियामा भएका गुनासोहरु</Typography>
          <Tooltip title="यी गुनासोहरू प्रक्रियामा छन्। कृपया कुनै पनि अपडेटहरू प्राप्त गरे पछि आवश्यक जानकारीका साथ अपडेट गर्नुहोस्।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <ComplaintsTable rows={inProgressComplaint} columns={columns} />
      </Box>
      <Box mb={3}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6">समाधान भईसकेका गुनासोहरू</Typography>
          <Tooltip title="यी गुनासोहरू पहिले नै हल भइसकेका गुनासोहरू हुन्। यी गुनासोहरूको लागि कुनै पनि प्रक्रिया आवश्यक छैन।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <ComplaintsTable rows={closedComplaint} columns={columns} />
      </Box>
      <Box mb={3}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography variant="h6">अनुपयुक्त गुनासोहरू</Typography>
          <Tooltip title="यी अनधिकृत गुनासोहरू हुन्। तपाईंले पहिले नै यो अनुचितको रूपमा अपडेट गरिसक्नुभएको छ, त्यसैले यिनिहरुको लागि कुनै पनि प्रक्रिया आवश्यक छैन।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <ComplaintsTable rows={inappropriateComplaint} columns={columns} />
      </Box>
    </>
  );
}
