import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  complaintSearchContainer: {
    padding: theme.spacing(2)
  },
  helpIcon: {
    marginLeft: theme.spacing(.5),
    marginRight: theme.spacing(.5),
    color: theme.palette.text.secondary,
  },
  complaintDetails: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));
export default styles;