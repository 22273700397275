import { AppBar, Avatar, Box, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import { Person as AccountIcon, ExitToApp as LogOutIcon, Menu as MenuIcon, Settings as SettingIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import JA, { API_URL } from "../../api/api";
import logo from "../../assets/img/logo.png";
// context
import { toggleSidebar, useLayoutDispatch } from "../../context/LayoutContext";
import { useUserDispatch } from "../../context/UserContext";
import { AppMisc } from "../../misc/appMisc";
import { AppUtils } from "../../utils/appUtils";
import { ERROR, LOGOUT_SUCCESS, ROLE_ADMIN, ROLE_MUNICIPALITY_ADMIN, ROLE_USER, SETTING_DATA, SOMETHING_WENT_WRONG, SUCCESS, USER_INFO } from "../../utils/constants";
import { SessionStorage } from "../../utils/storage/sessionStorage";
import AddAlertMessage from "../alert/Alert";
import styles from "./style";

export default function Header(props) {
  const [userFullName, setUserFullName] = useState("जन-आवाज");
  const [logoUrl, setLogoUrl] = useState(null);
  const [palikaName, setPalikaName] = useState(null);
  const userInfo = SessionStorage.getItem(USER_INFO);
  const userRole = AppUtils.getUserRole();
  const settingData = SessionStorage.getItem(SETTING_DATA);

  const classes = styles();
  let history = useHistory();

  // global
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [profileMenu, setProfileMenu] = useState(null);

  const getUserInfo = () => {
    JA.get(API_URL.user)
      .then(response => {
        if (response.data) {
          SessionStorage.setItem(USER_INFO, response.data);
          setUserFullName(response.data.fullName || "जन-आवाज");
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  useEffect(() => {
    userInfo ? setUserFullName(userInfo.fullName || "जन-आवाज") : getUserInfo();
  }, [userInfo]);

  useEffect(() => {
    if (userRole !== ROLE_ADMIN && userRole !== ROLE_USER) {
      if (settingData) {
        setLogoUrl(settingData.logoUrl?.absolutePath);
        setPalikaName(settingData.palikaName);
      } else {
        getSettingData();
      }
    }
  }, [settingData, userRole]);

  const getSettingData = () => {
    JA.get(API_URL.setting)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          SessionStorage.setItem(SETTING_DATA, jsondata.data)
          setLogoUrl(jsondata.data?.logoUrl?.absolutePath)
          setPalikaName(jsondata.data.palikaName);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const logOut = () => {
    JA.post(API_URL.logOut)
      .then(response => {
        let data = response.data;
        if (data.type === SUCCESS) {
          AppUtils.removeUserRef();
          userDispatch({ type: LOGOUT_SUCCESS });
          history.push("/login");
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <MenuIcon
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }}
          />
        </IconButton>
        <img src={logoUrl || logo} alt="जन-आवाज" width="36"></img>
        <Typography variant="h6" className={classes.brand}>
          {palikaName ? AppMisc.getMunicipalityName(palikaName) : 'जन-आवाज'}
        </Typography>
        <Box display="flex" className={classes.userProfileMenu} justifyContent="center" alignItems="center" onClick={e => setProfileMenu(e.currentTarget)}>
          <Typography variant="body2" className={classes.username}>
            {userFullName}
          </Typography>
          <Avatar alt="Avatar" src={logo} />
        </Box>
        <Menu anchorEl={profileMenu} open={Boolean(profileMenu)} onClose={() => setProfileMenu(null)} classes={{ paper: classes.profileMenu }} disableAutoFocusItem>
          <MenuItem className={classes.profileMenuItem}>
            <Link href="profile" variant="body1" className={classes.profileMenuLink}>
              <AccountIcon className={classes.profileMenuIcon} />
              Profile
            </Link>
          </MenuItem>
          {
            (userRole === ROLE_MUNICIPALITY_ADMIN) &&
            <MenuItem className={classes.profileMenuItem}>
              <Link href="setting" variant="body1" className={classes.profileMenuLink}>
                <SettingIcon className={classes.profileMenuIcon} />
                Setting
              </Link>
            </MenuItem>
          }
          <MenuItem className={classes.profileMenuItem}>
            <Link onClick={logOut} variant="body1" className={classes.profileMenuLink}>
              <LogOutIcon className={classes.profileMenuIcon} />
              Logout
            </Link>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
