import { Box, Button, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import CustomModal from "../../../components/modal/CustomModal";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ERROR, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import AddDepartmentModal from "./helpers/AddDepartmentModal";
import DepartmentsList from "./helpers/DepartmentsList";
import styles from "./style";

export default function Departments() {
  const classes = styles();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] = useState(false);
  const [departmentTableData, setDepartmentTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
  const [tableRowId, setTableRowId] = useState(null);

  useEffect(() => {
    getDepartmentsData();
  }, []);

  const handleAddDepartmentModalClose = () => {
    setModalDefaultValues({});
    setOpenAddDepartmentModal(false);
  }

  const getDepartmentsData = () => {
    JA.get(API_URL.department)
      .then(response => {
        setDepartmentTableData(response.data);
        setOpenAddDepartmentModal(false);
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleAddDepartmentModalSubmit = data => {
    if (modalDefaultValues.id) {
      data.id = modalDefaultValues.id;
    }
    JA.post(API_URL.department, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          handleAddDepartmentModalClose();
          getDepartmentsData();
        }
        AddAlertMessage({ type: response.data.type, message: response.data.message });
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const handleEditDepartment = id => {
    JA.get(API_URL.department + "/" + id).then(response => {
      setModalDefaultValues(response.data);
      setOpenAddDepartmentModal(true);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleDepartmentDelete = (id) => {
    setShowDeleteDepartmentModal(true);
    setTableRowId(id);
  }

  const deleteTableRow = () => {
    JA.delete(API_URL.department + "/" + tableRowId).then(response => {
      if (response.data.type === SUCCESS) {
        getDepartmentsData();
        setShowDeleteDepartmentModal(false);
      }
      AddAlertMessage({ type: response.data.type, message: response.data.message });
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.departmentsHeading}>
          <Typography variant="h6">सबै शाखा／कार्यालयहरु</Typography>
          <Box display="flex" alignItems="center">
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => { setOpenAddDepartmentModal(true) }}>नयाँ शाखा／कार्यालय थप्नुहोस्</Button>
          </Box>
        </Box>
        <Box textAlign="center">
          <CustomModal
            title="शाखा／कार्यालय हटाउनुहोस्।"
            onModalSubmit={deleteTableRow}
            showModal={showDeleteDepartmentModal}
            onModalClose={() => setShowDeleteDepartmentModal(false)}
            submitButtonText="हटाउनुहोस्"
          >
            <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित शाखा／कार्यालय लाई हटाउन चाहनुहुन्छ?</Typography>
            <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
          </CustomModal>
        </Box>
        <Box>
          <DepartmentsList tableData={departmentTableData} onEditRow={handleEditDepartment} onDeleteRow={handleDepartmentDelete} showActionColumn={departmentTableData.length !== 0} />
        </Box>
        <AddDepartmentModal handleModalSubmit={handleAddDepartmentModalSubmit} showAddDepartmentModal={openAddDepartmentModal} handleAddDepartmentModalClose={handleAddDepartmentModalClose} defaultValues={modalDefaultValues} />
      </Box>
    </>
  );
}