import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../../../api/api";
import AddAlertMessage from "../../../../../components/alert/Alert";
import CustomReactSelect from "../../../../../components/custom-react-select/CustomReactSelect";
import { ERROR, REQUIRED_FIELD, SOMETHING_WENT_WRONG } from '../../../../../utils/constants';

export default function DepartmentSelect(props) {
  const [departments, setDepartments] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    getDepartments();
  }, [props.palikaName]);

  useEffect(() => {
    setShowErrorMessage(props.errors)
  }, [props.errors])

  const getDepartments = () => {
    let GET_DEPARTMENTS_API_URL = props.palikaName ? API_URL.department + "?municipalityName=" + props.palikaName : API_URL.department;
    JA.get(GET_DEPARTMENTS_API_URL)
      .then(response => {
        let jsondata = response.data;
        let departmentList = [];
        if (jsondata.length > 0) {
          for (let i = 0; i < jsondata.length; i++) {
            departmentList.push({ value: jsondata[i].id, label: jsondata[i].name })
          }
        }
        setDepartments(departmentList);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  return (
    <>
      <CustomReactSelect
        placeholder={props.placeholder}
        name="departments"
        options={departments}
        defaultValue={props.defaultValue || (props.showDefaultValue && departments[0].value)}
        onChange={props.onChange}
        isMulti={props.isMulti}
        isClearable={Boolean(props.isMulti)}
        isDisabled={props.disabled}
        {...props}
      />
      {showErrorMessage && (<span className="error-message">{REQUIRED_FIELD}</span>)}
    </>
  );
}