import React from "react";
import { AppUtils } from "../../../utils/appUtils";
import { ROLE_MUNICIPALITY_ADMIN, ROLE_USER } from "../../../utils/constants";
import MunicipalityAdmin from "./helpers/MunicipalityAdmin";
import Representative from "./helpers/Representative";

export default function UserDashboard() {
  const userRole = AppUtils.getUserRole();
  return (
    <>
      {
        (userRole === ROLE_MUNICIPALITY_ADMIN || userRole === ROLE_USER) ? (
          <MunicipalityAdmin />
        ) : (
          <Representative role={userRole} />
        )
      }
    </>
  );
}
