import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  btnContainer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  settingContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  resetBtn: {
    marginRight: theme.spacing(1)
  },
  hiddenInput: {
    display: "none",
  },
  imageContainer: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.divider,
    borderRadius: theme.spacing(.5),
    maxHeight: theme.spacing(13),
    maxWidth: theme.spacing(19)
  },
  signature: {
    maxWidth: theme.spacing(16)
  }
}));
export default styles;