import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add as AddIcon, Delete, PhotoCamera } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddUserModal from "../../../components/add-user/AddUserModal";
import AddAlertMessage from "../../../components/alert/Alert";
import UserList from "../../../components/user-list/UserList";
import { ADD_USER_ALLOWED_ROLES_FOR_MUNICIPALITY_ADMIN, ERROR, FILE_SIZE_EXCEED, SETTING_DATA, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import { SessionStorage } from "../../../utils/storage/sessionStorage";
import styles from "./style";

export default function UserSetting() {
  const classes = styles();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [userTableData, setUserTableData] = useState([]);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [settingDefaultValues, setSettingDefaultValues] = useState({});

  useEffect(() => {
    getPalikaUsers();
    getSettingData();
  }, []);

  const getPalikaUsers = () => {
    JA.get(API_URL.palikaUsers).then(response => {
      setUserTableData(response.data);
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  };

  const handleAddUserModalClose = () => {
    setOpenAddUserModal(false);
    setModalDefaultValues({});
  };

  const submitAddUserModal = data => {
    if (modalDefaultValues.id) {
      data.id = modalDefaultValues.id;
    }
    JA.post(API_URL.user, data)
      .then(response => {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        if (response.data.type === SUCCESS) {
          handleAddUserModalClose();
          getPalikaUsers();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const editUserDetails = id => {
    JA.get(API_URL.user + "/palika-user/" + id)
      .then(response => {
        setModalDefaultValues(response.data);
        setOpenAddUserModal(true);
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  };

  const getSettingData = () => {
    JA.get(API_URL.setting)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setSettingDefaultValues(jsondata.data);
          SessionStorage.setItem(SETTING_DATA, jsondata.data)
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const handleLogoUploadChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      let fileSizeValid = file.size < (2 * 1024 * 1024);
      if (fileSizeValid) {
        var postdata = new FormData();
        postdata.append("files", file);
        JA.post(API_URL.setting + "/upload-logo", postdata)
          .then(response => {
            let jsondata = response.data;
            if (jsondata.type === SUCCESS) {
              getSettingData();
            }
            AddAlertMessage({
              type: jsondata.type,
              message: jsondata.message,
            });
          })
          .catch(error => {
            AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
          });
      } else {
        AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
      }
    }
  }

  const removeUploadedImage = () => {
    let postData = {
      filePath: settingDefaultValues?.logoUrl?.path,
      fileName: settingDefaultValues?.logoUrl?.name,
      fileType: "LOGO"
    }
    JA.post(API_URL.setting + "/delete", postData).then(response => {
      AddAlertMessage({ type: response.data.type, message: response.data.message })
      if (response.data.type === SUCCESS) {
        getSettingData();
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, SOMETHING_WENT_WRONG });
    });
  }

  return (
    <>
      <Box className={classes.setting}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" borderBottom={1} mb={3} pb={1}>
          <Typography variant="h5">प्रयोगकर्ताहरू</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => {
            setOpenAddUserModal(true);
          }}>नयाँ प्रयोगकर्ता थप गर्नुहोस्</Button>
        </Box>
        <UserList tableData={userTableData} showActionColumn={userTableData.length !== 0} onEditRow={editUserDetails} />
      </Box>
      <AddUserModal modalDefaultValues={modalDefaultValues} userRoles={ADD_USER_ALLOWED_ROLES_FOR_MUNICIPALITY_ADMIN} showAddUserModal={openAddUserModal} handleAddUserModalClose={handleAddUserModalClose} onSubmit={submitAddUserModal.bind(this)} />
      <Grid container className={classes.settingContainer} spacing={2}>
        <Grid item xs sm={6}>
          <Box display="flex" alignItems="center">
            <Typography>कार्यालयको लोगो: </Typography>
            <Box className={classes.imageContainer} mx={2}>
              {settingDefaultValues?.logoUrl && <img className={classes.signature} src={settingDefaultValues?.logoUrl.absolutePath} alt="stamp" />}
            </Box>
            <input name="stamp" accept="image/*" className={classes.hiddenInput} id="logo" type="file" onChange={handleLogoUploadChange} />
            <label htmlFor="logo">
              <Tooltip title="कृपया कार्यालयको लोगो चयन गर्दा 2MB भन्दा कम साइज भएको पारदर्शी (Transparent) फोटो चयन गर्नुहोस्।" placement="top" arrow>
                <IconButton color="primary" aria-label="upload stamp" component="span">
                  <PhotoCamera />
                </IconButton>
              </Tooltip>
            </label>
            {settingDefaultValues?.logoUrl && <Tooltip title="कार्यालयको लोगो हटाउनुहोस्।" placement="top" arrow>
              <IconButton color="secondary" aria-label="remove stamp" component="span" onClick={() => removeUploadedImage()}>
                <Delete />
              </IconButton>
            </Tooltip>}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
