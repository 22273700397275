import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  banner: {
    margin: -theme.spacing(3),
    minHeight: "324px",
  },
  bannerDetails: {
    textAlign: "center",
    color: "#fff"
  },
  item: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  light: {
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
  },
  whiteButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    },
  },
  officeDetails: {
    textAlign: "center",
  },
  fullWidth: {
    width: "100%"
  },
  officesList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
}));
export default styles;
