import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
export default function CustomReactSelect({ onChange, ...props }) {
  const [selectedOption, setSelectedOption] = useState(props.isMulti ? [] : null);
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {
          React.Children.map(children, child =>
            child && child.type !== Placeholder ? child : null
          )
        }
      </ValueContainer>
    );
  };

  useEffect(() => {
    let executeHandleChangeForDefaultValue = props.isMulti ? !!(props.defaultValue?.length) : props.defaultValue;
    executeHandleChangeForDefaultValue && handleSelectChange(findSelectedOptionObject(props.defaultValue));
  }, [props.defaultValue, props.options]);

  useEffect(() => {
    props.clearSelect && handleSelectChange(null);
  }, [props.clearSelect]);

  const findSelectedOptionObject = (value) => {
    if (props.isMulti) {
      return (
        value.map(item => (props.options.find(obj => obj.value === item)) || ({ value: item, label: item }))
      )
    } else {
      return props.options.find(obj => obj.value === value) || { value: value, label: value };
    }
  }

  const handleSelectChange = (options) => {
    let value = props.isMulti ? options?.map(item => item.value) : options?.value;
    setSelectedOption(props.isMulti ? (options || []) : options);
    onChange(props.name, value, options);
  }

  const styles = {
    valueContainer: (base, state) => ({
      ...base,
      overflow: "visible"
    }),
    placeholder: (base, state) => ({
      ...base,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -3 : "50%",
      transition: "top 0.2s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 12
    })
  }

  return (
    !props.creatable ? (
      <Select
        placeholder={props.label}
        name={props.name}
        options={props.options}
        className={props.className}
        classNamePrefix={props.classNamePrefix}
        value={selectedOption}
        onChange={handleSelectChange}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        isClearable={props.isClearable}
        styles={styles}
        {...props}
      />
    ) : (
      <CreatableSelect
        placeholder={props.label}
        name={props.name}
        options={props.options}
        className={props.className}
        classNamePrefix={props.classNamePrefix}
        value={selectedOption}
        onChange={handleSelectChange}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        isClearable={props.isClearable}
        styles={styles}
        {...props}
      />
    )
  );
}

CustomReactSelect.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isClearable: PropTypes.bool,
};

CustomReactSelect.defaultProps = {
  className: "select-sm",
  classNamePrefix: "react-select",
  isClearable: true,
}