import { Grid, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomModal from "../../../../components/modal/CustomModal";
import { REQUIRED_FIELD } from "../../../../utils/constants";

export default function AddDepartmentModal({ showAddDepartmentModal, handleAddDepartmentModalClose, handleModalSubmit, ...props }) {
  const { errors, handleSubmit, reset, register } = useForm();

  useEffect(() => {
    !showAddDepartmentModal && reset({});
  }, [showAddDepartmentModal, reset])

  const onSubmit = data => {
    handleModalSubmit(data);
  };

  return (
    <CustomModal
      title="कर्मचारी सूचना प्रणालीमा नयाँ शाखा／कार्यालय थप्नुहोस।"
      onModalSubmit={handleSubmit(onSubmit)}
      showModal={showAddDepartmentModal}
      onModalClose={handleAddDepartmentModalClose}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <TextField
            label="शाखा／कार्यालय को नाम:"
            name="name"
            type="text"
            size="small"
            variant="outlined"
            defaultValue={props.defaultValues.name}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.name && (<span className="error-message">{REQUIRED_FIELD}</span>)}
        </Grid>
      </Grid>
    </CustomModal>
  );

}