import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  faq: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  question: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1)
  },
  answer: {
    marginBottom: theme.spacing(2)
  }
}));
export default styles;